<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import logo from '../../../img/logo-v2.png'
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import mailIcon from "../../../img/icons/mailIcon.svg";
import lockIcon from "../../../img/icons/lockIcon.svg";
import DropdownLanguage from "@/Components/DropdownLanguage.vue";
import LanguageSelect from "@/Components/LanguageSelect.vue";


defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});



const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });

   
};

const navigateToRegister = () => {
    // router.visit('/r');
    window.location.href = '/r';
};
</script>

<template>
    <div
      class="text-right mr-10 mt-5 flex space-x-4"
      style="position: absolute; top: 0; right: 0"
    >
      <DropdownLanguage class="hidden md:block" />
    </div>
    <GuestLayout>
        <Head :title="$t('Log in')" />


      <div class="flex flex-col">
        <div class="flex">
          <span class="text-white font-semibold text-4xl">{{$t('Log in to previsit.')}}<span class="text-secondary-blue">ai</span></span>
          <img class="ml-auto ml-14 h-10" :src="logo" />
        </div>


        <form class="mt-8 flex flex-col" @submit.prevent="submit">
            <div>
                <InputLabel for="email" v-bind:value="$t('Email address')" />

                <TextInput
                    id="email"
                    type="email"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                    :placeholder="$t('your@email.com')"
                    :icon="mailIcon"
                />

                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-6">
                <InputLabel for="password" v-bind:value="$t('Password')" />

                <TextInput
                    id="password"
                    type="password"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                    placeholder="********"
                    :icon="lockIcon"
                />

                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="block mt-2">
                <label class="flex items-center">
                    <Checkbox name="remember" v-model:checked="form.remember" />
                    <span class="ml-4 text-white">{{$t('Remember me')}}</span>
                </label>
            </div>
          <PrimaryButton dusk="login-button" class="px-6 py-4 bg-main-blue mt-6 w-full items-center justify-center" :disabled="form.processing">
            <span class="text-white font-bold text-lg">{{ $t('Log in') }}</span>
          </PrimaryButton>
          <Link
              v-if="canResetPassword"
              :href="route('password.request')"
              class="no-underline text-white mt-4 self-center"
          >
            {{ $t('Forgot your password?') }} <span class="text-main-teal"> {{ $t('Reset') }}</span>
          </Link>
          <div class="text-white mt-4 self-center flex items-center w-full">
            <div class="flex-grow border-t border-white/20"></div>
            <span class="flex-shrink mx-6" >or</span>
            <div class="flex-grow border-t border-white/20"></div>
          </div>

          <Link
              @click="navigateToRegister"
              class="no-underline text-white mt-4 self-center"
          >
            {{ $t("I don't have an account yet.") }} <span class="text-main-teal"> {{ $t('Sign up') }}</span>
          </Link>

        </form>
      </div>
    </GuestLayout>
</template>
